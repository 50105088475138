<!-- CanvasComponent.vue -->

<template>
  <div>
    <canvas ref="myCanvas" width="400" height="200" id="canvas"></canvas>
  </div>
</template>

<script>
import PNGbackground from '../assets/images/superhero.png'; //Please be careful about image path It should be correct as per your image directory.

export default {
  data() {
        return {
            painting: false,
            canvas: null,
            ctx: null
        }
    },
  methods: {
    loadImg() {
            this.img = new Image();
            //this.img.crossOrigin = "Anonymous";

            this.img.onload = () => {
                this.ctx.drawImage(this.img, 0, 0);
            }

            //this.img.src = '../../public/body-outline.jpg';
            this.img.src = PNGbackground;
        },
    drawTextOnImage() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      this.loadImg();
      /*const image = new Image();
      image.src = PNGbackground;
      image.onload = () => {
        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0, this.width, this.height);

        // Set font properties
        ctx.font = '20px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';

        // Add text to the center of the canvas
        const text = 'You have 5 coins';
        const x = this.width / 2;
        const y = this.height / 2;
        ctx.fillText(text, x, y);
      };*/

    },mounted() {
    // Access the canvas context and draw on it
    this.drawTextOnImage();
  }}};
</script>

<style scoped>
/* Add scoped styles if needed */
</style>