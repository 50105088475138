<!--<template>
  <div>
    <youtube
      :video-id="videoId"
      :player-vars="playerVars"
      @ready="onPlayerReady"
      @ended="onPlayerEnded"
    ></youtube>
  </div>
</template>

<script>
import { ref } from 'vue';
import VueYoutubeEmbed from 'vue-youtube-embed';

export default {
  components: {
    VueYoutubeEmbed,
  },
  props: {
    videoId: String,
    playerVars: Object,
  },
  methods: {
    onPlayerReady(event) {
      // Handle player ready event
    },
    onPlayerEnded(event) {
      console.log('Video ended');
      // Perform actions when video ends
    },
  },
};
</script>

<script>
import { ref } from 'vue';
import VueYoutubeEmbed from 'vue-youtube-embed';

export default {
  components: {
    VueYoutubeEmbed,
  },
  props: {
    videoId: String,
    startAt: {
      type: Number,
      default: 0,
    },
    endAt: {
      type: Number,
      default: -1,
    },
    subtitles: {
      type: Object,
      default: null,
    },
    onEnd: Function,
  },
  setup(props) {
    const youtubePlayer = ref(null);

    const playerVars = {
      start: props.startAt,
      end: props.endAt,
      subtitles: props.subtitles,
      videoId:props.videoId,
      ended: props.onEnd

    };

    const onPlayerReady = () => {
      if (props.subtitles) {
        // Handle subtitles setup here
        console.log('Subtitles setup');
      }
    };

    const onPlayerEnded = () => {
      if (props.onEnd) {
        props.onEnd();
      }
    };

    return {
      youtubePlayer,
      playerVars,
      onPlayerReady,
      onPlayerEnded,
    };
  },
};
</script>
-->
<template>
    <iframe
      ref="youtubeIframe"
      width="640"
      height="360"
      :src="embedUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <div>
    <div ref="playerContainer"></div>
  </div>
</template>

<script>
export default {
  props: {
    videoId: String,
    startAt: {
      type: Number,
      default: 0,
    },
    endAt: {
      type: Number,
      default: 0,
    },
    subtitles: {
      type: Object,
      default: null,
    },
    onEndAction: Function,
  },
  computed: {
    embedUrl() {
      let url = `https://www.youtube.com/embed/${this.videoId}?autoplay=1`;
      if (this.startAt > 0) {
        url += `&start=${this.startAt}`;
      }
      if (this.endAt > this.startAt) {
        url += `&end=${this.endAt}`;
      }
      if (this.subtitles) {
        url += `&cc_load_policy=1&cc_lang_pref=${this.subtitles.language}`;
      }
      return url;
    },
  },
  mounted() {
    //console.log("works2")
   // this.$refs.youtubeIframe.addEventListener('ended', this.handleVideoEnded);
    window.onYouTubeIframeAPIReady = this.setupPlayer;
 //   this.loadYouTubeIframeAPI();


    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Callback function for when the API is ready
    // window.onYouTubeIframeAPIReady = () => {
    //   this.player = new YT.Player(this.$refs.playerContainer, {
    //     height: '360',
    //     width: '640',
    //     videoId: this.videoId,
    //     playerVars: {
    //       start: this.startAt,
    //       end: this.endAt,
    //       controls: 1
    //       // Other player options...
    //     },
    //     events: {
    //       'onReady': this.onPlayerReady,
    //       'onStateChange': this.onPlayerStateChange
    //     }
    //   });
    //};

  },
  beforeUnmount() {
   // this.$refs.youtubeIframe.removeEventListener('ended', this.handleVideoEnded);
  },
  methods: {
    handleVideoEnded() {
      if (this.onEndAction) {
        this.onEndAction();
      }
    },
    loadYouTubeIframeAPI() {
      //console.log("load");
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },
    setupPlayer() {
     // console.log("setupplayer");
      this.player = new YT.Player(this.$refs.youtubeIframe, {
        events: {
          onStateChange: this.onPlayerStateChange
        }
      });
      this.player.addEventListener('onStateChange', this.onPlayerStateChange)

      //console.log(this.player);
    },
    onPlayerStateChange(event) {
     // console.log("called");
      
    },
    onPlayerStateChange(event) {
     // console.log("called");
      if (event.data === YT.PlayerState.PLAYING) {
        this.checkEndTime();
      }
    },
    checkEndTime() {
      const currentTime = this.player.getCurrentTime();
    //  console.log(currentTime);
      if (currentTime >= this.endAt) {
        // Call your function when the end time is reached
        this.onEndTimeReached();
      } else {
        // Check again after a short delay
    //    console.log("work")
        setTimeout(this.checkEndTime, 1000); // Check every second
      }
    },
    onEndTimeReached() {
      // Your function to be called when the end time is reached
    //  console.log("End time reached!");
    }
  },
};
</script>
